import { FC, useCallback, useEffect, useState } from "react";

import {
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  TextField,
} from "@mui/material";
import { RadioGroup } from "@mui/material";

import { Button } from "component-library";
import { isEqual } from "lodash";

import { ChartSettingsModalParams } from "../../../types/charts/chartStore/chartStore";
import { IAssetEconomicsData } from "../../../types/charts/chartType/chartType";
import { AssetEconomicsConfigProps } from "../../../types/charts/chartTypeSettings/chartTypeSettings";
import { TargetUoMKey } from "../../../types/charts/chartTypeSettings/exonomicsTypeSettings";
import { Modules } from "../../../types/modularity/modules";

import { TARGET_UOM_ECONOMICS } from "../../../constants/charts/cashFlowChart";

import useChartStore from "../../../store/chart/chartStore";
import useModularityStore from "../../../store/modularity/modularityStore";

import useChartConfig from "../../../customHooks/charts/useChartConfig";

import { clone } from "../../../utils";

const CashFlowPlotConfig: FC<AssetEconomicsConfigProps> = ({
  chartData,
  handleClose,
}) => {
  const [targetUomValue, setTargetUomValue] = useState<TargetUoMKey>();
  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );
  const updateGetDataFlag = useChartStore(
    (state) => state.setChartDataRefetchFlag
  );
  const updateModuleDataByKey = useModularityStore(
    (state) => state.updateModuleDataByKey
  );
  const updateChartModalParam = useChartStore(
    (state) => state.setChartSettingsModalParams
  );

  const modules = useModularityStore((state) => state.modules);

  const {
    title,
    isInputTitleError,
    inputTitleValidationMessage,
    handleTitleOnChange,
    handleTitleOnBlur,
  } = useChartConfig(chartData);

  const handleSaveSettings = useCallback(() => {
    if (isInputTitleError) return;

    let shouldRefetch = false;
    const copiedChartData = clone(chartData) as IAssetEconomicsData;
    const module = modules.find((mod) => mod.module === chartData.chartId);
    const copiedModules = clone(module) as Modules;

    if (
      (!copiedChartData.targetUom &&
        targetUomValue !== TARGET_UOM_ECONOMICS.boe.key) ||
      (copiedChartData.targetUom &&
        !isEqual(targetUomValue, copiedChartData.targetUom))
    )
      shouldRefetch = true;

    updateChartDataByKey(chartData.chartId, {
      ...copiedChartData,
      title: title,
      targetUom: targetUomValue,
    });

    if (shouldRefetch)
      updateGetDataFlag({ chartId: chartData.chartId, refetch: shouldRefetch });

    updateModuleDataByKey(chartData.chartId, {
      ...copiedModules,
      title: title,
    });

    const chartConfigModalParams: ChartSettingsModalParams = {
      open: false,
      chartId: "",
      chartType: "",
    };

    updateChartModalParam(chartConfigModalParams);
  }, [title, targetUomValue, chartData.targetUom, modules]);

  const handleTargetUomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uom = e.target.value as TargetUoMKey;
    setTargetUomValue(uom);
  };

  useEffect(() => {
    if (chartData.targetUom) {
      setTargetUomValue(chartData.targetUom);
    } else {
      setTargetUomValue(TARGET_UOM_ECONOMICS.boe.key);
    }
  }, [chartData]);

  return (
    <>
      <DialogContent dividers className="economics-settings-container">
        <Grid item xs={12} className="section-item">
          <TextField
            id="chart-title"
            label="Title"
            size="small"
            helperText={isInputTitleError ? inputTitleValidationMessage : ""}
            error={isInputTitleError}
            value={title}
            onChange={handleTitleOnChange}
            onBlur={handleTitleOnBlur}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className="section-item">
          <FormControl fullWidth>
            <FormLabel className="chart-settings-text">
              Economic Units
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={targetUomValue}
              name="targetUom-buttons-group"
              onChange={handleTargetUomChange}
            >
              <FormControlLabel
                value={TARGET_UOM_ECONOMICS.boe.key}
                control={<Radio />}
                label={TARGET_UOM_ECONOMICS.boe.label}
                checked={targetUomValue === TARGET_UOM_ECONOMICS.boe.key}
              />

              <FormControlLabel
                value={TARGET_UOM_ECONOMICS.mcfe.key}
                control={<Radio />}
                label={TARGET_UOM_ECONOMICS.mcfe.label}
                checked={targetUomValue === TARGET_UOM_ECONOMICS.mcfe.key}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          type="secondary"
          iconLeft="chevronLeft"
          text="Cancel"
          className="chart-cancel-button"
        />
        <Button
          onClick={handleSaveSettings}
          disabled={isInputTitleError}
          type="primary"
          iconLeft="save"
          text="Save Settings"
        />
      </DialogActions>
    </>
  );
};

export default CashFlowPlotConfig;
