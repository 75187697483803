import { useCallback, useEffect, useMemo, useRef } from "react";

import { isEqual } from "lodash";

import { IAssetEconomicsData } from "../../types/charts/chartType/chartType";
import { setTimeoutType } from "../../types/common/general";
import {
  WellEconomicsInputField,
  WellEconomicsInputFields,
  WellEconomicsInputTypes,
  WellEconomicsInputs,
  WellEconomicsInputsError,
} from "../../types/panels/wellPanel/wellEconomicsType";

import * as constant from "../../constants/panels/wellPanel/wellEconomics";

import useAlertPopupStore from "../../store/map/alert/alertStore";
import usePanelsStore from "../../store/panels/panelsStore";

import { clone } from "../../utils/helper";
import { convertNegativeToParenthesis } from "../../utils/panel/wellPanel/economics";

import useWellEconomicsComputations from "../panels/wellPanel/useWellEconomicsComputations";

const useWellEconomicsAggregationRecalculate = (
  chartData?: IAssetEconomicsData
) => {
  const setTimeOutAlertRef = useRef<setTimeoutType | null>(null);
  const setTimeOutFieldAlertRef = useRef<setTimeoutType | null>(null);

  const wellEconomicsInputInfo = usePanelsStore(
    (state) => state.wellEconomicsInputs?.[chartData?.chartId ?? ""]
  );
  const addWellEconomicsInput = usePanelsStore(
    (state) => state.addWellEconomicsInput
  );
  const updateWellEconomicsError = usePanelsStore(
    (state) => state.updateWellEconomicsError
  );
  const updateWellEconomicChangedState = usePanelsStore(
    (state) => state.updateWellEconomicChangedState
  );

  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const { parseStringToNumber } = useWellEconomicsComputations();

  //either default or recalculated values
  const originalInputValues = useMemo(() => {
    return chartData?.assetEconomicsRawData?.userValuesBeforeRecalculation
      ? chartData?.assetEconomicsRawData?.userValuesBeforeRecalculation
      : chartData?.assetEconomicsRawData?.defaultValues.Inputs;
  }, [
    chartData?.assetEconomicsRawData?.recalculatedValues,
    chartData?.assetEconomicsRawData?.defaultValues,
  ]);

  const checkGreyedStateFalse = (greyedState: WellEconomicsInputsError) => {
    return (Object.keys(greyedState) as WellEconomicsInputFields[]).every(
      (k) => !greyedState[k]
    );
  };

  //parse string (ex: parenthesis) to number
  const parseInputValues = (wellEconomicsInput: WellEconomicsInputField) => {
    const inputs = clone(wellEconomicsInput) as WellEconomicsInputs;

    Object.keys(inputs).forEach((key) => {
      const keyField = key as WellEconomicsInputFields;

      if (inputs[keyField]) {
        const value = inputs[keyField] as string | number;
        const parsedValue = parseStringToNumber(value);

        inputs[keyField] = parsedValue;
      } else {
        inputs[keyField] = null;
      }
    });

    return inputs;
  };

  const reCheckAPIValidations = () => {
    if (!isGasTaxRateLiquidTaxRateValid(wellEconomicsInputInfo.inputs)) {
      updateWellEconomicsError(chartData?.chartId ?? "", {
        [constant.WELL_ECONOMICS_TAX_GAS.key]: true,
        [constant.WELL_ECONOMICS_TAX_LIQUIDS.key]: true,
      });
      showTaxFieldsErrorAlert();
      return false;
    }

    return true;
  };

  const isGasTaxRateLiquidTaxRateValid = (
    wellEconomicsInput: WellEconomicsInputField
  ) => {
    if (
      (wellEconomicsInput[constant.WELL_ECONOMICS_TAX_GAS.key] &&
        wellEconomicsInput[constant.WELL_ECONOMICS_TAX_LIQUIDS.key]) ||
      Boolean(
        wellEconomicsInput[constant.WELL_ECONOMICS_TAX_GAS.key] === null &&
          wellEconomicsInput[constant.WELL_ECONOMICS_TAX_LIQUIDS.key] === null
      ) ||
      Boolean(
        wellEconomicsInput[constant.WELL_ECONOMICS_TAX_GAS.key] === "" &&
          wellEconomicsInput[constant.WELL_ECONOMICS_TAX_LIQUIDS.key] === null
      ) ||
      Boolean(
        wellEconomicsInput[constant.WELL_ECONOMICS_TAX_GAS.key] === null &&
          wellEconomicsInput[constant.WELL_ECONOMICS_TAX_LIQUIDS.key] === ""
      ) ||
      Boolean(
        wellEconomicsInput[constant.WELL_ECONOMICS_TAX_GAS.key] === "" &&
          wellEconomicsInput[constant.WELL_ECONOMICS_TAX_LIQUIDS.key] === ""
      )
    ) {
      return true;
    }

    return false;
  };

  const showTaxFieldsErrorAlert = () => {
    if (setTimeOutAlertRef.current) {
      clearTimeout(setTimeOutAlertRef.current);
      setTimeOutAlertRef.current = null;
    }

    setTimeOutFieldAlertRef.current = setTimeout(() => {
      updateAlertState({
        severity: "error",
        message: "Populate both fields or leave both fields blank",
      });
    }, 500);
  };

  //check if values from new input is equal to default or recalculated input values
  //loop through new data, skip if null
  const checkEquality = useCallback(
    (newInputs: WellEconomicsInputs) => {
      let different = true;
      if (originalInputValues) {
        (Object.keys(newInputs) as WellEconomicsInputFields[]).forEach(
          (key) => {
            if (!isEqual(newInputs[key], originalInputValues[key])) {
              different = false;
            }
          }
        );
      }
      return different;
    },
    [originalInputValues]
  );

  const checkDefaultEquality = useCallback(
    (newInputs: WellEconomicsInputs) => {
      if (
        originalInputValues &&
        !isEqual(
          chartData?.assetEconomicsRawData?.defaultValues.Inputs,
          newInputs
        )
      ) {
        return false;
      }

      return true;
    },
    [chartData?.assetEconomicsRawData?.defaultValues]
  );

  const setGreyedStateNull = (inputs: WellEconomicsInputs) => {
    const greyedState = clone(
      wellEconomicsInputInfo.greyedState
    ) as WellEconomicsInputsError;

    //check if all greyedState fields are false
    if (checkGreyedStateFalse(greyedState)) {
      //if no field are greyed out then set NGL Differential to null
      //NGL Price, by default, will be recalculated
      inputs[constant.WELL_ECONOMICS_NGL_DIFFERENTIAL.key] = null;
    } else {
      Object.keys(inputs).forEach((key) => {
        const keyField = key as WellEconomicsInputFields;

        if (greyedState[keyField]) inputs[keyField] = null;
      });
    }

    return inputs;
  };

  //compare new inputs with default values
  //change input value to null if same value with default
  const formatDefaultInputsToNull = (newInputs: WellEconomicsInputs) => {
    Object.keys(newInputs).map((input) => {
      const inputField = input as WellEconomicsInputFields;
      if (
        inputField === constant.WELL_ECONOMICS_NGL_DIFFERENTIAL.key ||
        inputField === constant.WELL_ECONOMICS_NGL_PRICE.key
      ) {
        if (
          newInputs[inputField] !== null &&
          isEqual(
            newInputs[inputField],
            chartData?.assetEconomicsRawData?.defaultValues.Inputs[inputField]
          )
        )
          newInputs[inputField] = null;
      } else {
        if (
          isEqual(
            newInputs[inputField],
            chartData?.assetEconomicsRawData?.defaultValues.Inputs[inputField]
          )
        )
          newInputs[inputField] = null;
      }
    });

    return newInputs;
  };

  const addWellEconomicsByWellInput = (data: WellEconomicsInputs) => {
    const parsedData = convertNegativeToParenthesis(data);

    if (chartData) {
      addWellEconomicsInput({
        [chartData.chartId]: {
          inputs: parsedData,
          errorState: constant.WELL_ECONOMICS_FIELD_ERROR_VALUES,
          changedState: constant.WELL_ECONOMICS_FIELD_ERROR_VALUES,
          greyedState: constant.WELL_ECONOMICS_FIELD_ERROR_VALUES,
        },
      });
    }
  };

  useEffect(() => {
    if (wellEconomicsInputInfo && chartData) {
      const currentChangedState = clone(
        wellEconomicsInputInfo.changedState
      ) as WellEconomicsInputsError;
      const parsedInputValues = parseInputValues(wellEconomicsInputInfo.inputs);
      (
        Object.keys(
          constant.WELL_ECONOMICS_FIELD_ERROR_VALUES
        ) as WellEconomicsInputTypes[]
      ).forEach((field) => {
        //change state will always compare to default values
        if (
          !isEqual(
            parsedInputValues?.[field],
            chartData.assetEconomicsRawData?.defaultValues?.Inputs?.[field]
          )
        ) {
          currentChangedState[field] = true;
        } else {
          currentChangedState[field] = false;
        }
      });

      if (!isEqual(currentChangedState, wellEconomicsInputInfo.changedState)) {
        updateWellEconomicChangedState(chartData.chartId, currentChangedState);
      }
    }
  }, [chartData?.assetEconomicsRawData?.recalculatedValues]);
  return {
    originalInputValues,
    parseInputValues,
    reCheckAPIValidations,
    checkEquality,
    checkDefaultEquality,
    setGreyedStateNull,
    formatDefaultInputsToNull,
    addWellEconomicsByWellInput,
  };
};

export default useWellEconomicsAggregationRecalculate;
