import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";

import { StratModelBasinMapTypes } from "../../types/map/mapSettings/store";

import { LAYER_NAME_TO_LABEL_MAPPER } from "../../constants/constants";
import {
  DEFAULT_FORMATION_BY_BASIN_MAP_TYPE,
  STRATIGRAPHIC_MODELS,
  STRATIGRAPHIC_MODELS_BASINS_LABEL,
} from "../../constants/map/stratigraphicModels";

import useMapStore from "../../store/map/mapStore";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";

import AccordionSection from "../common/AccordionSection";

const StratigraphicLayerAccordion = () => {
  const stratModelsInfo = useMapSettingsStore((state) => state.stratModelsInfo);
  const toggleStratModelsSelected = useMapSettingsStore(
    (state) => state.toggleStratModelsSelected
  );
  const updateStratModelsBasinStatus = useMapSettingsStore(
    (state) => state.updateStratModelsBasinStatus
  );
  const updateStratModelsBasinMapType = useMapSettingsStore(
    (state) => state.updateStratModelsBasinMapType
  );
  const updateStratModelsBasinFormation = useMapSettingsStore(
    (state) => state.updateStratModelsBasinFormation
  );
  const toggleLayer = useMapStore((state) => state.toggleLayer);

  const handleChangeBasinFormation = (
    isParentLayerSelected: boolean,
    isBasinSelected: boolean,
    newFormationName: string,
    basinName: string
  ) => {
    if (isParentLayerSelected && isBasinSelected) {
      // remove previous layer and add new layer
      toggleLayer(stratModelsInfo.basins[basinName].formationName, false);
      toggleLayer(newFormationName, true);
    }
    updateStratModelsBasinFormation(basinName, newFormationName);
  };

  const handleToggleParentLayer = () => {
    const { isSelected } = stratModelsInfo;
    const newState = !isSelected;
    toggleStratModelsSelected(newState);

    Object.values(stratModelsInfo.basins).forEach((basin) => {
      const { formationName, isSelected } = basin;
      toggleLayer(formationName, newState && isSelected);
    });
  };

  const handleToggleBasin = (basinName: string) => {
    const { isSelected } = stratModelsInfo.basins[basinName];
    const newState = !isSelected;
    if (newState && !stratModelsInfo.isSelected) handleToggleParentLayer();

    updateStratModelsBasinStatus(basinName, newState);
    if (newState) {
      handleChangeBasinFormation(
        newState,
        newState,
        stratModelsInfo.basins[basinName].formationName,
        basinName
      );
    } else {
      toggleLayer(stratModelsInfo.basins[basinName].formationName, false);
    }
  };

  const handleChangeMapType = (
    e: React.ChangeEvent<HTMLInputElement>,
    basinName: string
  ) => {
    const mapType = e.target.value as StratModelBasinMapTypes;
    const newLayerByBasinMapType = DEFAULT_FORMATION_BY_BASIN_MAP_TYPE[
      basinName
    ][mapType] as StratModelBasinMapTypes;
    updateStratModelsBasinMapType(
      basinName,
      e.target.value as StratModelBasinMapTypes
    );
    handleChangeBasinFormation(
      stratModelsInfo.isSelected,
      stratModelsInfo.basins[basinName].isSelected,
      newLayerByBasinMapType,
      basinName
    );
  };

  return (
    <AccordionSection
      title="TGS Stratigraphic Models"
      className="stratigraphic-models-accordion"
      hasCheckbox
      expandIconEnd
      isSelected={stratModelsInfo.isSelected}
      onSelect={() => handleToggleParentLayer()}
    >
      {STRATIGRAPHIC_MODELS.map(({ basinName, mapTypesInfo }) => (
        <AccordionSection
          key={basinName}
          title={STRATIGRAPHIC_MODELS_BASINS_LABEL[basinName]}
          className="nested-sub-accordion with-radio-group"
          hasCheckbox
          expandIconEnd
          isSelected={stratModelsInfo.basins[basinName].isSelected}
          onSelect={() => handleToggleBasin(basinName)}
        >
          <RadioGroup
            defaultValue={mapTypesInfo[0].mapType}
            value={stratModelsInfo.basins[basinName].mapType}
            onChange={(e) => handleChangeMapType(e, basinName)}
          >
            {mapTypesInfo.map(({ mapType, formations }) => (
              <div
                key={`${basinName}-${mapType}`}
                className="radio-btn-with-dropdown"
              >
                <FormControlLabel
                  value={mapType}
                  control={<Radio size="small" />}
                  label={mapType}
                />
                {stratModelsInfo.basins[basinName].mapType === mapType && (
                  <FormControl className="custom-select">
                    <InputLabel id="formation-name-select">
                      Formations
                    </InputLabel>
                    <Select
                      labelId="formation-name-select"
                      label="Formations"
                      defaultValue={formations[0]}
                      value={stratModelsInfo.basins[basinName].formationName}
                      onChange={(e) =>
                        handleChangeBasinFormation(
                          stratModelsInfo.isSelected,
                          stratModelsInfo.basins[basinName].isSelected,
                          e.target.value,
                          basinName
                        )
                      }
                    >
                      {formations.map((name) => (
                        <MenuItem key={name} value={name}>
                          {LAYER_NAME_TO_LABEL_MAPPER[name]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
            ))}
          </RadioGroup>
        </AccordionSection>
      ))}
    </AccordionSection>
  );
};

export default StratigraphicLayerAccordion;
