import { StateCreator } from "zustand";

import { WellEconomicsSlice } from "../../types/panels/wellPanel/wellEconomicsStore";
import { WellEconomicsInputsInfo } from "../../types/panels/wellPanel/wellEconomicsType";

import { WELL_ECONOMICS_FIELD_ERROR_VALUES } from "../../constants/panels/wellPanel/wellEconomics";

import { clone } from "../../utils/helper";

const wellEconomicSlice: StateCreator<WellEconomicsSlice> = (set, get) => ({
  isWellEconomicsLoading: false,
  updateIsWellEconomicsLoading: (isWellEconomicsLoading) =>
    set(() => ({ isWellEconomicsLoading })),
  wellEconomicsInputs: {},
  addWellEconomicsInput: (data) =>
    set(() => ({
      wellEconomicsInputs: get().wellEconomicsInputs
        ? { ...get().wellEconomicsInputs, ...data }
        : { ...data },
    })),
  updateWellEconomicsInputByField: (id, field, value) =>
    set(() => ({
      wellEconomicsInputs: {
        ...get().wellEconomicsInputs,
        [id]: {
          ...get().wellEconomicsInputs?.[id],
          inputs: {
            ...get().wellEconomicsInputs?.[id].inputs,
            [field]: value,
          },
        },
      },
    })),
  updateWellEconomicsError: (id, errorValues) =>
    set(() => ({
      wellEconomicsInputs: {
        ...get().wellEconomicsInputs,
        [id]: {
          ...get().wellEconomicsInputs[id],
          errorState: {
            ...get().wellEconomicsInputs[id].errorState,
            ...errorValues,
          },
        },
      },
    })),
  updateWellEconomicChangedStateByField: (id, field, value) =>
    set(() => ({
      wellEconomicsInputs: {
        ...get().wellEconomicsInputs,
        [id]: {
          ...get().wellEconomicsInputs[id],
          changedState: {
            ...get().wellEconomicsInputs[id].changedState,
            [field]: value,
          },
        },
      },
    })),

  updateWellEconomicChangedState: (id, changedValues) =>
    set(() => ({
      wellEconomicsInputs: {
        ...get().wellEconomicsInputs,
        [id]: {
          ...get().wellEconomicsInputs[id],
          changedState: {
            ...get().wellEconomicsInputs[id].changedState,
            ...changedValues,
          },
        },
      },
    })),

  updateWellEconomicsGreyedState: (id, values) =>
    set(() => ({
      wellEconomicsInputs: {
        ...get().wellEconomicsInputs,
        [id]: {
          ...get().wellEconomicsInputs[id],
          greyedState: {
            ...get().wellEconomicsInputs[id].greyedState,
            ...values,
          },
        },
      },
    })),

  resetWellEconomicsStateValues: (id, states) =>
    set(() => {
      const newStates = new Map();

      states.forEach((state) => {
        switch (state) {
          case "error":
            newStates.set("errorState", WELL_ECONOMICS_FIELD_ERROR_VALUES);
            break;
          case "changed":
            newStates.set("errorState", WELL_ECONOMICS_FIELD_ERROR_VALUES);
            break;
        }
      });

      return {
        wellEconomicsInputs: {
          ...get().wellEconomicsInputs,
          [id]: {
            ...get().wellEconomicsInputs[id],
            ...newStates,
          },
        },
      };
    }),
  removeWellEconomicsByIds: (ids) =>
    set(() => {
      let newWellEconomicsList = get().wellEconomicsInputs;

      if (ids.length) {
        newWellEconomicsList = clone(
          get().wellEconomicsInputs
        ) as WellEconomicsInputsInfo;

        ids.forEach((id) => {
          if (get().wellEconomicsInputs[id]) {
            delete newWellEconomicsList[id];
          }
        });
      }

      return {
        wellEconomicsInputs: newWellEconomicsList,
      };
    }),
});

export { wellEconomicSlice };
