import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";

import { ChartSettingsModalParams } from "../../types/charts/chartStore/chartStore";
import {
  ChartDataFields,
  IAssetEconomicsData,
  IChartTypeInfo,
  IScatterPlotChartData,
  YAxisScaleType,
} from "../../types/charts/chartType/chartType";
import { ChartTypeSettingsProps } from "../../types/charts/chartTypeSettings/chartTypeSettings";
import { Modules } from "../../types/modularity/modules";

import { CHART_TYPES } from "../../constants/charts/charts";
import {
  PRODUCTION_PLOT,
  SCATTER_PLOT,
  TYPE_CURVE,
} from "../../constants/constants";

import useChartStore from "../../store/chart/chartStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useModularityStore from "../../store/modularity/modularityStore";

import {
  sortChartDataAlphabetical,
  sortModulesAlphabetical,
} from "../../utils/charts/sorting";

import { darkTheme } from "../../themes";
import { clone } from "../../utils";
import AssetEconomicsConfig from "./chartTypeSettings/AssetEconomicsConfig";
import ProductionPlotConfig from "./chartTypeSettings/ProductionPlotConfig";
import ScatterPlotConfig from "./chartTypeSettings/ScatterPlotConfig";
import TypeCurveConfig from "./chartTypeSettings/TypeCurveConfig";

const ChartTypeSettings = ({
  open,
  chartType,
  chartId,
}: ChartTypeSettingsProps) => {
  const [currentChartData, setCurrentChartData] = useState<
    IChartTypeInfo | undefined
  >(undefined);
  const modules = useModularityStore((state) => state.modules);
  const updateModuleDataByKey = useModularityStore(
    (state) => state.updateModuleDataByKey
  );
  const chartDataList = useChartStore((state) => state.chartData);
  const updateGetDataFlag = useChartStore(
    (state) => state.setChartDataRefetchFlag
  );
  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );
  const updateChartModalParam = useChartStore(
    (state) => state.setChartSettingsModalParams
  );

  const selectedWellIdsKeys = useMapSelectionStore(
    (state) => state.selectedWellIdsKeys
  );
  useEffect(() => {
    const chartInfo: IChartTypeInfo | undefined = chartDataList.find(
      (data: IChartTypeInfo) => data.chartId === chartId
    );

    setCurrentChartData(chartInfo);
  }, [chartDataList, chartId]);

  //Check if there are changes on the data fields to display selection
  //if true, refetch data else does not refetch data
  const isDataFieldsToDisplayEqual = (
    originalCopy: ChartDataFields[],
    updatedCopy: ChartDataFields[]
  ) => {
    //NOTE: a combination of data name and accumulation method is unique
    const originalCopyList = originalCopy
      .map((data) => `${data.name} ${data.accumMethod}`)
      .sort()
      .join(",");
    const updatedCopyList = updatedCopy
      .map((data) => `${data.name} ${data.accumMethod}`)
      .sort()
      .join(",");

    if (originalCopyList === updatedCopyList) {
      return true;
    } else {
      return false;
    }
  };

  const handleSaveSettings = (
    title: string,
    aggregate: string,
    showForecast: boolean,
    showDailyValues: boolean,
    showByNormalizedLateralLength: boolean,
    scaling: YAxisScaleType,
    dataFieldsToDisplay: ChartDataFields[],
    id: string
  ) => {
    let shouldRefetch = false;
    const copiedChartDataList: IChartTypeInfo[] = clone(chartDataList);
    const modifiedChartData: IChartTypeInfo[] = copiedChartDataList.map(
      (chart: IChartTypeInfo) => {
        if (chart.chartId === id) {
          chart.title = title;

          if (
            chart.objectType === CHART_TYPES.PRODUCTION_PLOT ||
            chart.objectType === CHART_TYPES.TYPE_CURVE
          ) {
            if (
              (chart.groupBy !== aggregate ||
                !isDataFieldsToDisplayEqual(
                  chart.chartDisplayedDataFields,
                  dataFieldsToDisplay
                ) ||
                chart.chartShowForecast !== showForecast ||
                chart.chartShowDailyValues !== showDailyValues ||
                chart.chartShowByNormalizedLateralLength !==
                  showByNormalizedLateralLength) &&
              selectedWellIdsKeys.length
            ) {
              shouldRefetch = true; //only refetch data on change of selected aggregate
            }

            chart.groupBy = aggregate;
            chart.chartShowForecast = showForecast;
            chart.chartShowDailyValues = showDailyValues;
            chart.chartShowByNormalizedLateralLength =
              showByNormalizedLateralLength;
            chart.chartYAxisScaling = scaling;
            chart.chartDisplayedDataFields = dataFieldsToDisplay;
          }
        }
        return chart;
      }
    );
    sortChartDataAlphabetical(modifiedChartData);
    const updatedChartData: IChartTypeInfo | undefined = modifiedChartData.find(
      (chart: IChartTypeInfo) => chart.chartId === id
    );
    if (updatedChartData) {
      updateChartDataByKey(id, updatedChartData);
    }

    const copiedModules: Modules[] = clone(modules);
    const modifiedModules: Modules[] = copiedModules.map((chart) => {
      if (chart.module === id) {
        chart.title = title;
      }
      return chart;
    });
    sortModulesAlphabetical(modifiedModules);

    const updatedModule: Modules | undefined = modifiedModules.find(
      (module) => module.module === id
    );
    if (updatedModule) updateModuleDataByKey(id, updatedModule);

    const chartConfigModalParams: ChartSettingsModalParams = {
      open: false,
      chartId: "",
      chartType: "",
    };

    updateGetDataFlag({ chartId: chartId, refetch: shouldRefetch });
    updateChartModalParam(chartConfigModalParams);
  };

  const handleCloseModal = () => {
    const chartConfigModalParams: ChartSettingsModalParams = {
      open: false,
      chartId: "",
      chartType: "",
    };
    updateChartModalParam(chartConfigModalParams);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Dialog
        className="chart-config-modal"
        maxWidth="md"
        open={open && Boolean(currentChartData)}
        fullWidth
      >
        <DialogTitle>
          <Box>
            <Typography>Chart Settings | {chartType}</Typography>
            <IconButton size="small" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        {chartType === PRODUCTION_PLOT && (
          <ProductionPlotConfig
            handleClose={handleCloseModal}
            handleSaveSettings={handleSaveSettings}
            data={currentChartData}
          />
        )}
        {chartType === TYPE_CURVE && (
          <TypeCurveConfig
            handleClose={handleCloseModal}
            handleSaveSettings={handleSaveSettings}
            data={currentChartData}
          />
        )}
        {chartType === SCATTER_PLOT && (
          <ScatterPlotConfig
            handleClose={handleCloseModal}
            chartData={currentChartData as IScatterPlotChartData}
          />
        )}
        {chartType === CHART_TYPES.ASSET_ECONOMICS && currentChartData && (
          <AssetEconomicsConfig
            handleClose={handleCloseModal}
            chartData={currentChartData as IAssetEconomicsData}
          />
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default ChartTypeSettings;
