import { useState } from "react";

import axios from "axios";

import { WellEconomicsRequestParameters } from "../../../types/common/wellEconomics";
import {
  WellEconomicsInputTypes,
  WellEconomicsResponse,
} from "../../../types/panels/wellPanel/wellEconomicsType";

import config from "../../../configs/appSettings";

import { SELF } from "../../../constants/constants";
import { WELL_ECONOMICS_TARGET_UOM } from "../../../constants/panels/wellPanel/wellEconomics";
import { WELL_ECONOMICS_SECTION } from "../../../constants/panels/wellPanel/wellInfo";

import useAlertPopupStore from "../../../store/map/alert/alertStore";

const useWellEconomicsOutputData = () => {
  const [data, setData] = useState<WellEconomicsResponse>();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);

  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const getWellEconomicsOutputData = async ({
    uwi,
    inputs,
    summaryOnly = true,
    auditVerbosity,
    includeFcfDetails,
    isForAnalysisExport = false, // will prevent isLoading from triggering
    targetUom = WELL_ECONOMICS_TARGET_UOM.BOE,
  }: WellEconomicsRequestParameters) => {
    setSuccess(false);
    setData(undefined);
    setError(null);

    if (isForAnalysisExport) {
      setLoadingExport(true);
    } else {
      setLoading(true);
    }
    try {
      const body = {
        Uwis: uwi,
        SummaryOnly: summaryOnly,
        ...(inputs ? { Inputs: inputs } : {}),
        ...(auditVerbosity ? { AuditVerbosity: auditVerbosity } : {}),
        ...(includeFcfDetails ? { IncludeFcfDetails: true } : {}), //will include request for FreeCashFlow
        ...(targetUom ? { TargetUom: targetUom } : {}),
      };

      const { data } = await axios.post<WellEconomicsResponse>(
        `${config.endpoints.productDelivery}/${WELL_ECONOMICS_SECTION.urlSlug}`,
        body
      );

      if (
        !data.Result.Success &&
        (data.Result.Audits?.[0].Audit || data.Result.AuditsList)
      ) {
        if (data.Result.Audits?.[0].Audit) {
          updateAlertState({
            message: `API Error: ${data.Result.Audits[0].Audit}`,
            severity: "error",
          });
        } else if (data.Result.AuditsList) {
          updateAlertState({
            message: `No economic data available for selected well(s)`,
            severity: "warning",
          });
        }
      }

      if ((!summaryOnly && inputs) || summaryOnly) {
        const dataWithRoundedInputs = data.Inputs;
        (Object.keys(data.Inputs) as WellEconomicsInputTypes[]).forEach(
          (input) =>
            (dataWithRoundedInputs[input] =
              data.Inputs[input] !== null || data.Inputs[input] !== undefined
                ? Number(data.Inputs[input]?.toFixed(3)) //round numbers to 3 decimal places
                : null)
        );
        setData({ ...data, Inputs: dataWithRoundedInputs });
        setSuccess(true);
        setLoading(false);
        setLoadingExport(false);
      } else if (!summaryOnly && !inputs) {
        setData(undefined); //prevent update if default
        setSuccess(true);
        setLoading(false);
        setLoadingExport(false);
      }

      if (!summaryOnly && data.Url) {
        window.open(data.Url, SELF);
      }
    } catch (error: any) {
      console.debug("Error fetching well economics", error);
      setLoading(false);
      setError(error);
      setLoading(false);
    }
  };

  return {
    getWellEconomicsOutputData,
    data,
    success,
    error,
    loading,
    loadingExport,
  };
};

export default useWellEconomicsOutputData;
