import { useCallback } from "react";

import {
  WellEconomicsInputFields,
  WellEconomicsInputsError,
} from "../../../types/panels/wellPanel/wellEconomicsType";

import * as constant from "../../../constants/panels/wellPanel/wellEconomics";
import { regexNumberAndSpecialChar } from "../../../constants/regexp";

import useAlertPopupStore from "../../../store/map/alert/alertStore";
import usePanelsStore from "../../../store/panels/panelsStore";

import { clone } from "../../../utils";
import useWellEconomicsComputations from "./useWellEconomicsComputations";

const useWellEconomicsEvents = (id: number | string) => {
  const wellEconomicsInputInfo = usePanelsStore(
    (state) => state.wellEconomicsInputs?.[id]
  );
  const updateWellEconomicsInputByField = usePanelsStore(
    (state) => state.updateWellEconomicsInputByField
  );
  const updateWellEconomicsError = usePanelsStore(
    (state) => state.updateWellEconomicsError
  );

  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const updateWellEconomicsGreyedState = usePanelsStore(
    (state) => state.updateWellEconomicsGreyedState
  );

  const { computeNGLFormula, getFormattedValue } =
    useWellEconomicsComputations();

  const handleInputChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: WellEconomicsInputFields
    ) => {
      if (regexNumberAndSpecialChar.test(e.currentTarget.value)) {
        const value: string | number = e.currentTarget.value;
        if (wellEconomicsInputInfo?.inputs) {
          updateWellEconomicsInputByField(id, field, value);
        }

        triggerErrorFields(field, value);
      }
    },
    [wellEconomicsInputInfo?.inputs, id]
  );

  //onBlur, add comma/s and parenthesis if negative(-) value
  //and remove trailing zeroes for non currency
  const handleOnBlur = useCallback(
    (field: WellEconomicsInputFields) => {
      if (
        wellEconomicsInputInfo?.inputs &&
        wellEconomicsInputInfo?.inputs[field] !== null
      ) {
        const fieldValue = getFormattedValue(
          field,
          wellEconomicsInputInfo.inputs[field]
        );
        if (fieldValue) {
          updateWellEconomicsInputByField(id, field, fieldValue);

          triggerErrorFields(field, fieldValue);
        }
      }
    },
    [wellEconomicsInputInfo?.inputs]
  );

  //Loop and revalidate all the fields before recalculating
  const validateBeforeRecalculate = () => {
    let formattingError = false;
    (
      Object.keys(wellEconomicsInputInfo.inputs) as WellEconomicsInputFields[]
    ).forEach((inputField) => {
      const valueToCheck =
        wellEconomicsInputInfo.inputs[inputField]?.toString() ?? "";
      if (
        valueToCheck !== "" &&
        !checkFieldFormatting(inputField, valueToCheck)
      ) {
        formattingError = true;
        updateWellEconomicsError(id, {
          [inputField]: true,
        });
      }
    });

    if (formattingError) {
      updateAlertState({
        message: "Please make sure to use proper formatting for each fields.",
        severity: "error",
      });
      return false;
    }

    return true;
  };

  //check the correct field formatting of a field
  const checkFieldFormatting = (
    field: WellEconomicsInputFields,
    eventValue: string
  ) => {
    if (
      constant.WELL_ECONOMICS_INPUTS_CONSTANTS[field].regex.some((regex) =>
        regex.test(eventValue)
      )
    )
      return true;
    return false;
  };

  const triggerErrorFields = (
    field: WellEconomicsInputFields,
    value: string
  ) => {
    if (checkFieldFormatting(field, value)) {
      if (
        constant.WELL_ECONOMICS_TAX_GAS.key === field ||
        constant.WELL_ECONOMICS_TAX_LIQUIDS.key === field
      ) {
        updateWellEconomicsError(id, {
          [constant.WELL_ECONOMICS_TAX_GAS.key]: false,
          [constant.WELL_ECONOMICS_TAX_LIQUIDS.key]: false,
        });
      } else {
        updateWellEconomicsError(id, {
          [field]: false,
        });
      }

      if (
        field === constant.WELL_ECONOMICS_NGL_DIFFERENTIAL.key ||
        constant.WELL_ECONOMICS_NGL_PRICE.key === field
      ) {
        const currentGreyedState = clone(
          wellEconomicsInputInfo.greyedState
        ) as WellEconomicsInputsError;

        if (value !== "" && value !== null) {
          const oilPrice =
            wellEconomicsInputInfo.inputs[
              constant.WELL_ECONOMICS_OIL_PRICE.key
            ];
          if (oilPrice !== null) {
            const computedNGLValue = computeNGLFormula(field, value, oilPrice);
            if (computedNGLValue) {
              if (field === constant.WELL_ECONOMICS_NGL_DIFFERENTIAL.key) {
                updateWellEconomicsInputByField(
                  id,
                  constant.WELL_ECONOMICS_NGL_PRICE.key,
                  computedNGLValue
                );

                updateWellEconomicsGreyedState(id, {
                  ...currentGreyedState,
                  [field]: false,
                  [constant.WELL_ECONOMICS_NGL_PRICE.key]: true,
                });
              } else if (field === constant.WELL_ECONOMICS_NGL_PRICE.key) {
                updateWellEconomicsInputByField(
                  id,
                  constant.WELL_ECONOMICS_NGL_DIFFERENTIAL.key,
                  computedNGLValue
                );

                updateWellEconomicsGreyedState(id, {
                  ...currentGreyedState,
                  [field]: false,
                  [constant.WELL_ECONOMICS_NGL_DIFFERENTIAL.key]: true,
                });
              }
            }
          }
        } else {
          updateWellEconomicsGreyedState(id, {
            ...currentGreyedState,
            [constant.WELL_ECONOMICS_NGL_DIFFERENTIAL.key]: false,
            [constant.WELL_ECONOMICS_NGL_PRICE.key]: false,
          });
        }
      }
    } else {
      updateWellEconomicsError(id, {
        [field]: true,
      });
    }
  };

  return {
    handleInputChange,
    handleOnBlur,
    validateBeforeRecalculate,
  };
};

export default useWellEconomicsEvents;
