import { ChartType } from "../../types/charts/chartType/chartType";

import {
  DEFAULT_ATTRIBUTE_COLOR,
  DEFAULT_TOP_COLORS,
} from "../../constants/map/mapSettings";

import { formatNumberWithDecimals } from "../formatters/numberFormatter";
import { digitToMultiplierMapper, digits_count } from "../helper";
import { truncateString } from "../stringUtils";

const legendNameAcronymRegEx = /\([^()]*\)$/gm;

const formatName = (
  fullName: string,
  rawName: string,
  truncationLength: number,
  matchedAcronym: RegExpMatchArray | null
) => {
  let formattedName = "";
  if (fullName.length > truncationLength) {
    formattedName = truncateString(fullName, truncationLength).concat(
      matchedAcronym?.[0] ?? ""
    );
  } else {
    formattedName = rawName;
  }

  return formattedName;
};

export const legendNameFormatter = (name: string, truncationLength: number) => {
  let formattedName = "";
  const matchedAcronym = name.match(legendNameAcronymRegEx);

  if (matchedAcronym) {
    const fullName = name.replace(matchedAcronym[0], "");
    formattedName = formatName(
      fullName,
      name,
      truncationLength,
      matchedAcronym
    );
  } else {
    formattedName = truncateString(name, truncationLength);
  }

  return formattedName;
};

export const formatTraceName = (
  fieldHasDaily: boolean,
  chartSettingShowDaily: boolean,
  fieldDailyDisplayName: string,
  fieldDefaultDisplayName: string,
  isForForecastData: boolean
) => {
  let formattedTraceName = "";

  if (fieldHasDaily && chartSettingShowDaily) {
    formattedTraceName = fieldDailyDisplayName;
  } else {
    formattedTraceName = fieldDefaultDisplayName;
  }

  if (isForForecastData) {
    formattedTraceName += " Forecast";
  }

  return formattedTraceName;
};

export const formattedTraceText = (
  formattedTraceName: string,
  formattedUnit: string,
  hasUnit: boolean
) => {
  let formattedTraceText = formattedTraceName;

  if (hasUnit) {
    formattedTraceText += `, ${formattedUnit}`;
  }

  return formattedTraceText;
};

export const formatColorsToRGB = (color: number[]) => {
  return `rgb(${color[0]},${color[1]},${color[2]})`;
};

export const formatDefaultTopColorsToRGB = () => {
  const colors: string[] = [];

  DEFAULT_TOP_COLORS.forEach((color) => {
    colors.push(formatColorsToRGB(color));
  });

  return colors;
};

export const formatDefaultColorToRGB = () => {
  return formatColorsToRGB(DEFAULT_ATTRIBUTE_COLOR);
};

export const formatHoverValue = (fieldValue: number) => {
  if (fieldValue < 10) {
    return formatNumberWithDecimals(fieldValue, 3, false) as string;
  } else {
    return formatNumberWithDecimals(fieldValue, 0, false) as string;
  }
};

export const digitToUnitCurrencyMapper = (digit: number) => {
  if (digit < 4) {
    return "";
  } else if (digit < 7) {
    return "K";
  } else if (digit < 10) {
    return "M";
  } else if (digit < 13) {
    return "B";
  } else if (digit < 16) {
    return "T";
  } else {
    return "";
  }
};

export const transformToCurrencyTick = (num: number) => {
  const digits = digits_count(num);
  const unit = digitToUnitCurrencyMapper(digits);
  const positiveVal = Math.abs(num);
  const shortenedValue = positiveVal / digitToMultiplierMapper(digits);
  let formattedStringValue = `${shortenedValue}${unit}`;

  if (num < 0) {
    formattedStringValue = `(${formattedStringValue})`;
  }
  return `$${formattedStringValue}`;
};

export const formatChartTitle = (chartType: ChartType, chartId: string) => {
  return `${chartType}_${chartId}`;
};
