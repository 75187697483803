import { useState } from "react";

import { IChartDataInfo } from "../../types/charts/chartType/chartType";

import useChartStore from "../../store/chart/chartStore";

import { formatChartTitle } from "../../utils/charts/formatter";
import { hasEmoji } from "../../utils/charts/validator";

const useChartConfig = ({
  title: tempTitle,
  chartId,
  chartType,
}: Pick<IChartDataInfo, "title" | "chartId" | "chartType">) => {
  const [isInputTitleError, setIsInputTitleError] = useState(false);
  const [inputTitleValidationMessage, setInputTitleValidationMessage] =
    useState("");

  const [title, setTitle] = useState(
    tempTitle === "" ? formatChartTitle(chartType, chartId) : tempTitle
  );

  const chartDataList = useChartStore((state) => state.chartData);

  const handleTitleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isInputTitleError) {
      setIsInputTitleError(false);
      setInputTitleValidationMessage("");
    }

    setTitle(e.target.value);
  };

  const isTitleExisting = () => {
    return (
      chartDataList.filter(
        (chart) =>
          (chart.title === title ||
            formatChartTitle(chart.chartType, chart.chartId) === title) &&
          chart.chartId !== chartId
      ).length > 0
    );
  };

  const handleTitleOnBlur = () => {
    if (!title) {
      setInputTitleValidationMessage("Invalid Chart Title");
      setIsInputTitleError(true);
    } else {
      if (isTitleExisting()) {
        setInputTitleValidationMessage("Duplicated Chart Title");
        setIsInputTitleError(true);
      }

      if (hasEmoji(title)) {
        setInputTitleValidationMessage("Invalid Chart Title");
        setIsInputTitleError(true);
      }
    }
  };

  return {
    title,
    isInputTitleError,
    inputTitleValidationMessage,
    handleTitleOnChange,
    handleTitleOnBlur,
  };
};

export default useChartConfig;
