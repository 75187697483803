import { LayerObject } from "../types/map/layers/store";

import { getDefaultCartoStyle } from "../components/charts/utils/mapSettingsUtils";

import { CUM_BOE } from "./attributes";
import {
  ANADARKO_01_STONE_CORRAL_STRUCTURE,
  ANADARKO_02_TOP_HUTCHINSON_STRUCTURE,
  ANADARKO_03_BASE_HUTCHINSON_STRUCTURE,
  ANADARKO_04_CHESTER_STRUCTURE,
  ANADARKO_05_WABAUNSEE_STRUCTURE,
  ANADARKO_06_HEEBNER_SHALE_STRUCTURE,
  ANADARKO_07_LANSING_STRUCTURE,
  ANADARKO_08_HOXBAR_KANSAS_CITY_STRUCTURE,
  ANADARKO_09_MARMATON_STRUCTURE,
  ANADARKO_10_CHEROKEE_STRUCTURE,
  ANADARKO_11_ATOKA_STRUCTURE,
  ANADARKO_12_TOP_EROSION_STRUCTURE,
  ANADARKO_13_SPRINGER_STRUCTURE,
  ANADARKO_14_CHESTER_LIME_STRUCTURE,
  ANADARKO_15_MISSISSIPPI_LIME_STRUCTURE,
  ANADARKO_16_WOODFORD_STRUCTURE,
  ANADARKO_17_HOSSTON_STRUCTURE,
  ANADARKO_18_SYLVAN_SHALE_STRUCTURE,
  ANADARKO_19_VIOLA_STRUCTURE,
  ANADARKO_20_SIMPSON_STRUCTURE,
  ANADARKO_21_ARBUCKLE_STRUCTURE,
  APPALACHIA_STORAGE_UNIT_LAYERS,
  BOTTOM_PERMIT_SPOTS,
  BOTTOM_WELL_SPOTS,
  BOUGUER_ALY,
  BUBBLE_MAP,
  EAGLE_FORD_02_VICKSBURG_GROUP_GRID,
  EAGLE_FORD_03B_LOWER_CLAIBORNE_GRID,
  EAGLE_FORD_03_CLAIBORNE_GROUP_GRID,
  EAGLE_FORD_04A_MIDDLE_WILCOX_GRID,
  EAGLE_FORD_04B_LOWER_WILCOX_GRID,
  EAGLE_FORD_04_UPPER_WILCOX_GRID,
  EAGLE_FORD_05_MIDWAY_GROUP_GRID,
  EAGLE_FORD_06_NAVARRO_GROUP_GRID,
  EAGLE_FORD_07A_ANACACHO_GRID,
  EAGLE_FORD_07_TAYLOR_GROUP_GRID,
  EAGLE_FORD_08_AUSTIN_CHALK_GRID,
  EAGLE_FORD_09_UPPER_EAGLE_FORD_GRID,
  EAGLE_FORD_10A_WOODBINE_GRID,
  EAGLE_FORD_10B_MANESS_GRID,
  EAGLE_FORD_10_LOWER_EAGLE_FORD_GRID,
  EAGLE_FORD_11A_DEL_RIO_GRID,
  EAGLE_FORD_11B_GEORGETOWN_GRID,
  EAGLE_FORD_11C_EDWARDS_GRID,
  EAGLE_FORD_11_BUDA_GRID,
  EAGLE_FORD_12_GLEN_ROSE_GRID,
  EAGLE_FORD_13_PEARSAL_GRID,
  EAGLE_FORD_14_SLIGO_PETTET_GRID,
  EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS,
  FIRST_ST_BONE_SPRINGS_ISOPACH,
  GPB_01_RUSTLER,
  GPB_02_SALADO,
  GPB_03A_CHERRY_CANYON_QUEEN,
  GPB_03_BELL_CANYON_TANSIL,
  GPB_04A_GLORIETA,
  GPB_04_BRUSHY_CANYON_SAN_ANDRES,
  GPB_05_TOP_BS_CLEARFORK,
  GPB_06A1_MIDDLE_SPRABERRY,
  GPB_06A2_2ND_BONE_SPRING_LIME,
  GPB_06B1_LOWER_SPRABERRY,
  GPB_06B2_2ND_BONE_SPRING_SAND,
  GPB_06C0_LOWER_SPRABERRY_SHALE,
  GPB_06C1_3RD_BONE_SPRING_LIME,
  GPB_06C2_3RD_BONE_SPRING_LIME_SANDSTONE_TOP,
  GPB_06C3_3RD_BONE_SPRING_LIME_SANDSTONE_BASE,
  GPB_06D_3RD_BONE_SPRING_SAND,
  GPB_06D_DEAN_SAND,
  GPB_06_1ST_BS_SAND_WICHITA_SPRABERRY,
  GPB_07A1_WOLFCAMP_X,
  GPB_07A2_WOLFCAMP_Y,
  GPB_07A3_WOLFCAMP_SHALE,
  GPB_07B1_MID_WOLFCAMP_B,
  GPB_07B2_DEL_WOLFCAMP_B,
  GPB_07C_INTRA_WOLFCAMP_C,
  GPB_07_TOP_WOLFCAMP,
  GPB_08_CISCO_WOLFCAMP_D,
  GPB_09_STRAWN,
  GPB_10_BARNETT,
  GPB_11_MISSISSIPPIAN_LIMESTONE,
  GPB_12_WOODFORD_SHALE,
  GPB_13_DEVONIAN_CARBONATE,
  GPB_14_SILURIAN_SHALE,
  GPB_15_FUSSLEMAN,
  GPB_16_SIMPSON,
  GPB_17_ELLENBURGER,
  GPB_18_BASEMENT,
  GULF_COAST_STORAGE_UNIT_LAYERS,
  ILLINOIS_BASIN_STORAGE_UNIT_LAYERS,
  ISOSTATIC_ALY,
  LAND_GRIDS,
  LOWERSPRABERRY_ISOPACH,
  MAGNETIC_ALY,
  MAJOR_BASINS,
  MAP_SCALE_BAR,
  MAP_SEARCH_PIPELINES,
  MICHIGAN_STORAGE_UNIT_LAYERS,
  MIDDLESPRABERRY_ISOPACH,
  MID_15A_MONTOYA,
  OFFSHORE_BLOCKS,
  PERMIT_SPOTS,
  SANANDRES_ISOPACH,
  SCOOP_STACK_PLAY_AREAS,
  SECOND_BONE_SPRINGS_ISOPACH,
  SHADED_ALY,
  STRATIGRAPHIC_MODELS_AREA,
  STRUCTURAL_ELEMENTS,
  TGS_SURVEYS,
  THIRD_BONE_SPRINGS_ISOPACH,
  TRENDS,
  UPPERSPRABERRY_ISOPACH,
  WELL_PATH_AND_STICKS,
  WELL_SPOTS,
  WOLFCAMPX_ISOPACH,
  WOLFCAMPY_ISOPACH,
  WOODFORD_FIELDS,
  WOODFORD_ISOPACH,
  WOODFORD_LIMIT,
  WOODFORD_MATURITY,
  WOODFORD_STRUCTURE,
} from "./constants";
import {
  COLOR,
  DEFAULT_COLOR,
  DEFAULT_SIZE,
  MEDIUM,
  SIZE,
  UNIFORM_COLOR,
} from "./map/mapSettings";

export const eastGulfCoastBasinStorageUnitLayersInfo: LayerObject[] = [
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.VICKSBURG,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.CLAIBORNE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.UP_WILCOX,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LR_WILCOX,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.TAYLOR,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.AUSTIN,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LR_TUSCA,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LR_CRETA,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.PALUXY,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.TRINITY,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.RODESSA,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.SLIGO,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.PETTET,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.HOSSTON_TRAVIS_PEAK,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.COTTONVALLEY,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LRCOTTONVALLEY,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.BOSSIER,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.HAYNESVILLE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.SMACKOVER,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LRSMACKOVER,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.NORPHLET,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.EAGLEMILLS,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
];

export const illinoisBasinStorageUnitLayersInfo: LayerObject[] = [
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.PENNSYLVANIAN,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.INTRA_PENNSYLVANIAN,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.LOWER_PENNSYLVANIAN,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.TOP_MISSISSIPPIAN,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MISSISSIPPIAN_STE_GENEVIEVE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.ULIN,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.BORDEN,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.NEW_ALBANY_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.DEVONIAN_CARBONATE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.INTRA_DEVONIAN,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MOCCASIN_SPRINGS,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MAQUOKETA,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.TRENTON,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.ST_PETER_SANDSTONE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.KNOX,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.INTRA_KNOX,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.CAMBRIAN,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.IRONTON,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.EAU_CLAIRE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MOUNT_SIMON_SANDSTONE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MIDDLE_MOUNT_SIMON,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.LOWER_MOUNT_SIMON,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.ARGENTA,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
];

export const gulfCoastBasinStorageUnitLayersInfo: LayerObject[] = [
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.UPPER_PLIOCENE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.LOWER_PLIOCENE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.UPPER_MIOCENE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.MIDDLE_MIOCENE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.AMPH_B,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.LOWER_MIOCENE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.ANAHUAC,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.FRIO,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.GULF_COAST_BASIN_VICKSBURG,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.UPPER_CLAIBORNE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.LOWER_CLAIBORNE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.UPPER_WILCOX,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.MIDDLE_WILCOX,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.LOWER_WILCOX,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: GULF_COAST_STORAGE_UNIT_LAYERS.MIDWAY,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
];

export const michiganBasinStorageUnitLayersInfo: LayerObject[] = [
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_KINDERHOOKIAN,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_HOT_ANTRIM_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_TRAVERSE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BELL_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_DUNDEE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_DETROIT_RIVER_GROUP,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_LWR_DETROIT_RIVER_GROUP,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BOIS_BLANC_GARDEN_ISLAND,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BASS_ISLAND_GROUP,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_TOP_SALINA_GROUP,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_SALINA_C,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_MID_SALINA,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_NIAGRAN_BASE_SALINA,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_CABOT_HEAD_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_QUEENSTON_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_UTICA_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_TRENTON,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BLACK_RIVER,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_GLENWOOD,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_ST_PETER_SANDSTONE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_PRAIRIE_DU_CHIEN,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_TREMPEALEAU,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_MUNISING_GROUP,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_GALESVILLE_SANDSTONE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_EAU_CLAIRE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_INTRA_EAU_CLAIRE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_MOUNT_SIMON_SANDSTONE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_MIDDLE_MOUNT_SIMON_SANDSTONE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_LOWER_MOUNT_SIMON_SANDSTONE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BASAL_MOUNT_SIMON_SANDSTONE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
];

export const appalachiaBasinStorageUnitLayersInfo: LayerObject[] = [
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_BEREA_BRALLIER,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_OHIO_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_HURON_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_JAVA_OLENTANGY,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_RHINESTREET_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_MARCELLUS,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_ONONDAGA_ORISKANY,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_HELDERBERG_LIMESTONE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_SALINA_GROUP,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_SALINA_EVAPORITES,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_SALINA_C,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_LOCKPORT_DOLOMITE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_ROCHESTER_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_CLINTON_MEDINA,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_QUEENSTON_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_CINCINNATI_GROUP,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_UTICA_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_POINT_PLEASANT,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_TRENTON,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_BLACK_RIVER,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_WELLS_CREEK,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_KNOX,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_ROSE_RUN,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_COOPER_RIDGE_DOLOMITE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_LOWER_COOPER_RIDGE_DOLOMITE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_NULICHUCKY_SHALE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_MARRYVILLE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_MOUNT_SIMON_SANDSTONE,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
  {
    name: APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_BASEMENT,
    isSelected: false,
    isDisabled: false,
    minZoom: 3,
  },
];

export const defaultLayers: LayerObject[] = [
  { name: WELL_SPOTS, isSelected: true, isDisabled: false },
  { name: BOTTOM_WELL_SPOTS, isSelected: false, isDisabled: false },
  { name: WELL_PATH_AND_STICKS, isSelected: true, isDisabled: false },
  { name: PERMIT_SPOTS, isSelected: false, isDisabled: false },
  { name: BOTTOM_PERMIT_SPOTS, isSelected: false, isDisabled: false },
  { name: BUBBLE_MAP, isSelected: false, isDisabled: false },
  { name: MAP_SCALE_BAR, isSelected: false, isDisabled: false },
  { name: BOUGUER_ALY, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: ISOSTATIC_ALY, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: MAGNETIC_ALY, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: SHADED_ALY, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: LAND_GRIDS, isSelected: false, isDisabled: true, minZoom: 12 },
  {
    name: MAP_SEARCH_PIPELINES,
    isSelected: false,
    isDisabled: true,
    minZoom: 12,
  },
  { name: OFFSHORE_BLOCKS, isSelected: false, isDisabled: true, minZoom: 5 },
  { name: MAJOR_BASINS, isSelected: false, isDisabled: true, minZoom: 5 },
  { name: TGS_SURVEYS, isSelected: false, isDisabled: true, minZoom: 5 },
  {
    name: STRATIGRAPHIC_MODELS_AREA,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  { name: TRENDS, isSelected: false, isDisabled: true, minZoom: 5 },
  { name: GPB_01_RUSTLER, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: GPB_02_SALADO, isSelected: false, isDisabled: true, minZoom: 3 },
  {
    name: GPB_03_BELL_CANYON_TANSIL,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_03A_CHERRY_CANYON_QUEEN,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_04_BRUSHY_CANYON_SAN_ANDRES,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  { name: GPB_04A_GLORIETA, isSelected: false, isDisabled: true, minZoom: 3 },
  {
    name: GPB_05_TOP_BS_CLEARFORK,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_06_1ST_BS_SAND_WICHITA_SPRABERRY,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_06A1_MIDDLE_SPRABERRY,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_06A2_2ND_BONE_SPRING_LIME,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_06B1_LOWER_SPRABERRY,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_06B2_2ND_BONE_SPRING_SAND,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_06C0_LOWER_SPRABERRY_SHALE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_06C1_3RD_BONE_SPRING_LIME,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_06C2_3RD_BONE_SPRING_LIME_SANDSTONE_TOP,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_06C3_3RD_BONE_SPRING_LIME_SANDSTONE_BASE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_06D_3RD_BONE_SPRING_SAND,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  { name: GPB_06D_DEAN_SAND, isSelected: false, isDisabled: true, minZoom: 3 },
  {
    name: GPB_07_TOP_WOLFCAMP,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_07A1_WOLFCAMP_X,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_07A2_WOLFCAMP_Y,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_07A3_WOLFCAMP_SHALE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_07B2_DEL_WOLFCAMP_B,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_07B1_MID_WOLFCAMP_B,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },

  {
    name: GPB_07C_INTRA_WOLFCAMP_C,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_08_CISCO_WOLFCAMP_D,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  { name: GPB_09_STRAWN, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: GPB_10_BARNETT, isSelected: false, isDisabled: true, minZoom: 3 },
  {
    name: GPB_11_MISSISSIPPIAN_LIMESTONE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_12_WOODFORD_SHALE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_13_DEVONIAN_CARBONATE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: GPB_14_SILURIAN_SHALE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  { name: GPB_15_FUSSLEMAN, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: MID_15A_MONTOYA, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: GPB_16_SIMPSON, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: GPB_17_ELLENBURGER, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: GPB_18_BASEMENT, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: SANANDRES_ISOPACH, isSelected: false, isDisabled: true, minZoom: 3 },
  {
    name: FIRST_ST_BONE_SPRINGS_ISOPACH,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: UPPERSPRABERRY_ISOPACH,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: SECOND_BONE_SPRINGS_ISOPACH,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: MIDDLESPRABERRY_ISOPACH,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: LOWERSPRABERRY_ISOPACH,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: THIRD_BONE_SPRINGS_ISOPACH,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  { name: WOLFCAMPX_ISOPACH, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: WOLFCAMPY_ISOPACH, isSelected: false, isDisabled: true, minZoom: 3 },
  {
    name: EAGLE_FORD_02_VICKSBURG_GROUP_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_03_CLAIBORNE_GROUP_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_03B_LOWER_CLAIBORNE_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_04_UPPER_WILCOX_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_04A_MIDDLE_WILCOX_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_04B_LOWER_WILCOX_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_05_MIDWAY_GROUP_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_06_NAVARRO_GROUP_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_07_TAYLOR_GROUP_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_07A_ANACACHO_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_08_AUSTIN_CHALK_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_09_UPPER_EAGLE_FORD_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_10_LOWER_EAGLE_FORD_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_10A_WOODBINE_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_10B_MANESS_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_11_BUDA_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_11A_DEL_RIO_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_11B_GEORGETOWN_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_11C_EDWARDS_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_12_GLEN_ROSE_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_13_PEARSAL_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: EAGLE_FORD_14_SLIGO_PETTET_GRID,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_01_STONE_CORRAL_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_02_TOP_HUTCHINSON_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_03_BASE_HUTCHINSON_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_04_CHESTER_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_05_WABAUNSEE_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_06_HEEBNER_SHALE_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_07_LANSING_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_08_HOXBAR_KANSAS_CITY_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_09_MARMATON_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_10_CHEROKEE_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_11_ATOKA_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_12_TOP_EROSION_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_13_SPRINGER_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_14_CHESTER_LIME_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_15_MISSISSIPPI_LIME_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_16_WOODFORD_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_17_HOSSTON_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_18_SYLVAN_SHALE_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_19_VIOLA_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_20_SIMPSON_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: ANADARKO_21_ARBUCKLE_STRUCTURE,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  { name: WOODFORD_LIMIT, isSelected: false, isDisabled: true, minZoom: 3 },
  {
    name: STRUCTURAL_ELEMENTS,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  {
    name: SCOOP_STACK_PLAY_AREAS,
    isSelected: false,
    isDisabled: true,
    minZoom: 3,
  },
  { name: WOODFORD_ISOPACH, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: WOODFORD_STRUCTURE, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: WOODFORD_FIELDS, isSelected: false, isDisabled: true, minZoom: 3 },
  { name: WOODFORD_MATURITY, isSelected: false, isDisabled: true, minZoom: 3 },
  ...eastGulfCoastBasinStorageUnitLayersInfo,
  ...illinoisBasinStorageUnitLayersInfo,
  ...gulfCoastBasinStorageUnitLayersInfo,
  ...michiganBasinStorageUnitLayersInfo,
  ...appalachiaBasinStorageUnitLayersInfo,
];

export const defaultLayerStyles = {
  [WELL_SPOTS]: {
    [SIZE]: getDefaultCartoStyle(WELL_SPOTS, SIZE)?.key ?? MEDIUM.key,
    [COLOR]: UNIFORM_COLOR.key,
  },
  [BOTTOM_WELL_SPOTS]: {
    [SIZE]: getDefaultCartoStyle(BOTTOM_WELL_SPOTS, SIZE)?.key ?? MEDIUM.key,
    [COLOR]: UNIFORM_COLOR.key,
  },
  [WELL_PATH_AND_STICKS]: {
    [SIZE]: getDefaultCartoStyle(WELL_PATH_AND_STICKS, SIZE)?.key ?? MEDIUM.key,
    [COLOR]: UNIFORM_COLOR.key,
  },
  [BUBBLE_MAP]: {
    [SIZE]: CUM_BOE.key,
    [COLOR]: UNIFORM_COLOR.key,
  },
  [PERMIT_SPOTS]: {
    [SIZE]: getDefaultCartoStyle(PERMIT_SPOTS, SIZE)?.key ?? MEDIUM.key,
    [COLOR]: UNIFORM_COLOR.key,
  },
  [BOTTOM_PERMIT_SPOTS]: {
    [SIZE]: getDefaultCartoStyle(BOTTOM_PERMIT_SPOTS, SIZE)?.key ?? MEDIUM.key,
    [COLOR]: UNIFORM_COLOR.key,
  },
} as const;

export const defaultLayerColors = {
  [WELL_SPOTS]: [],
  [PERMIT_SPOTS]: [],
  [BUBBLE_MAP]: [
    {
      key: "All Bubbles",
      label: "All Bubbles",
      value: getDefaultCartoStyle(BUBBLE_MAP, DEFAULT_COLOR),
    },
  ],
} as const;

export const defaultLayerColorsLoading = {
  [WELL_SPOTS]: false,
  [BOTTOM_WELL_SPOTS]: false,
  [PERMIT_SPOTS]: false,
} as const;

export const defaultBubbleSizes = [
  {
    key: "All Bubbles",
    label: "All Bubbles",
    value: getDefaultCartoStyle(BUBBLE_MAP, DEFAULT_SIZE),
  },
] as const;
