import { useEffect, useState } from "react";

import { Data } from "plotly.js";

import { FreeCashFlowData } from "../../types/charts/chartType/cashFlowChartData";

import useAlertPopupStore from "../../store/map/alert/alertStore";

import { formatCashflowTrace } from "../../utils/charts/cashflowFormatter";

import useWellEconomicsOutputData from "../panels/wellPanel/useWellEconomicsData";

interface UseCashFlowChartDataProps {
  updateModuleRefetch?: () => void;
}

const useCashFlowChartData = ({
  updateModuleRefetch,
}: UseCashFlowChartDataProps) => {
  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );
  const [chartData, setChartData] = useState<Data[]>([]);
  const {
    data: wellEconomicsResponseData,
    loading: isLoading,
    getWellEconomicsOutputData,
  } = useWellEconomicsOutputData();

  const formatChartTrace = (cashFlowData: FreeCashFlowData) => {
    const formattedChartData = formatCashflowTrace(cashFlowData);
    setChartData(formattedChartData);
  };

  useEffect(() => {
    if (isLoading || !wellEconomicsResponseData) return;

    if (wellEconomicsResponseData.FreeCashFlow) {
      formatChartTrace(wellEconomicsResponseData.FreeCashFlow);
    } else {
      updateAlertState({
        severity: "warning",
        message: "No economic data available for selected well(s).",
      });
      updateModuleRefetch?.();
    }
  }, [isLoading, wellEconomicsResponseData]);

  return {
    isLoading,
    cashFlowData: wellEconomicsResponseData?.FreeCashFlow,
    cashFlowChartTraceData: chartData,
    getCashFlowChartData: getWellEconomicsOutputData,
  };
};

export default useCashFlowChartData;
