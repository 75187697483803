import {
  APPALACHIA_STORAGE_UNIT_LAYERS,
  EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS,
  GULF_COAST_STORAGE_UNIT_LAYERS,
  ILLINOIS_BASIN_STORAGE_UNIT_LAYERS,
  MICHIGAN_STORAGE_UNIT_LAYERS,
} from "../constants";

export const CARBON_STORAGE_LABEL = "TGS CO₂ Storage Assessment";

export const CARBON_LAYER_BASINS = {
  AR_MS_AL_STORAGE_CAPACITY: "AR-MS-AL Storage Capacity",
  ILLINOIS_STORAGE_CAPACITY: "Illinois Storage Capacity",
  GULF_COAST_STORAGE_CAPACITY: "Gulf Coast Storage Capacity",
  MICHIGAN_STORAGE_CAPACITY: "Michigan Storage Capacity",
  APPALACHIA_STORAGE_CAPACITY: "Appalachia Storage Capacity",
};

export const ORDERED_CARBON_LAYERS = [
  {
    key: CARBON_LAYER_BASINS.APPALACHIA_STORAGE_CAPACITY,
    label: "Appalachia Basin",
  },
  {
    key: CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY,
    label: "East Gulf Coast Basin",
  },
  {
    key: CARBON_LAYER_BASINS.GULF_COAST_STORAGE_CAPACITY,
    label: "Gulf Coast Basin",
  },
  {
    key: CARBON_LAYER_BASINS.ILLINOIS_STORAGE_CAPACITY,
    label: "Illinois Basin",
  },
  {
    key: CARBON_LAYER_BASINS.MICHIGAN_STORAGE_CAPACITY,
    label: "Michigan Basin",
  },
];

export const CARBON_STORAGE_FORMATIONS_BY_BASINS = {
  [CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY]: [
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.VICKSBURG,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.CLAIBORNE,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.UP_WILCOX,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LR_WILCOX,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.TAYLOR,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.AUSTIN,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LR_TUSCA,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LR_CRETA,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.PALUXY,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.TRINITY,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.RODESSA,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.SLIGO,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.PETTET,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.HOSSTON_TRAVIS_PEAK,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.COTTONVALLEY,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LRCOTTONVALLEY,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.BOSSIER,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.HAYNESVILLE,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.SMACKOVER,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LRSMACKOVER,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.NORPHLET,
    EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.EAGLEMILLS,
  ],
  [CARBON_LAYER_BASINS.ILLINOIS_STORAGE_CAPACITY]: [
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.PENNSYLVANIAN,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.INTRA_PENNSYLVANIAN,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.LOWER_PENNSYLVANIAN,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.TOP_MISSISSIPPIAN,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MISSISSIPPIAN_STE_GENEVIEVE,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.ULIN,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.BORDEN,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.NEW_ALBANY_SHALE,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.DEVONIAN_CARBONATE,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.INTRA_DEVONIAN,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MOCCASIN_SPRINGS,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MAQUOKETA,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.TRENTON,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.ST_PETER_SANDSTONE,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.KNOX,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.INTRA_KNOX,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.CAMBRIAN,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.IRONTON,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.EAU_CLAIRE,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MOUNT_SIMON_SANDSTONE,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MIDDLE_MOUNT_SIMON,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.LOWER_MOUNT_SIMON,
    ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.ARGENTA,
  ],
  [CARBON_LAYER_BASINS.GULF_COAST_STORAGE_CAPACITY]: [
    GULF_COAST_STORAGE_UNIT_LAYERS.UPPER_PLIOCENE,
    GULF_COAST_STORAGE_UNIT_LAYERS.LOWER_PLIOCENE,
    GULF_COAST_STORAGE_UNIT_LAYERS.UPPER_MIOCENE,
    GULF_COAST_STORAGE_UNIT_LAYERS.MIDDLE_MIOCENE,
    GULF_COAST_STORAGE_UNIT_LAYERS.AMPH_B,
    GULF_COAST_STORAGE_UNIT_LAYERS.LOWER_MIOCENE,
    GULF_COAST_STORAGE_UNIT_LAYERS.ANAHUAC,
    GULF_COAST_STORAGE_UNIT_LAYERS.FRIO,
    GULF_COAST_STORAGE_UNIT_LAYERS.GULF_COAST_BASIN_VICKSBURG,
    GULF_COAST_STORAGE_UNIT_LAYERS.UPPER_CLAIBORNE,
    GULF_COAST_STORAGE_UNIT_LAYERS.LOWER_CLAIBORNE,
    GULF_COAST_STORAGE_UNIT_LAYERS.UPPER_WILCOX,
    GULF_COAST_STORAGE_UNIT_LAYERS.MIDDLE_WILCOX,
    GULF_COAST_STORAGE_UNIT_LAYERS.LOWER_WILCOX,
    GULF_COAST_STORAGE_UNIT_LAYERS.MIDWAY,
  ],
  [CARBON_LAYER_BASINS.MICHIGAN_STORAGE_CAPACITY]: [
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_KINDERHOOKIAN,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_HOT_ANTRIM_SHALE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_TRAVERSE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BELL_SHALE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_DUNDEE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_DETROIT_RIVER_GROUP,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_LWR_DETROIT_RIVER_GROUP,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BOIS_BLANC_GARDEN_ISLAND,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BASS_ISLAND_GROUP,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_TOP_SALINA_GROUP,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_SALINA_C,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_MID_SALINA,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_NIAGRAN_BASE_SALINA,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_CABOT_HEAD_SHALE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_QUEENSTON_SHALE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_UTICA_SHALE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_TRENTON,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BLACK_RIVER,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_GLENWOOD,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_ST_PETER_SANDSTONE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_PRAIRIE_DU_CHIEN,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_TREMPEALEAU,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_MUNISING_GROUP,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_GALESVILLE_SANDSTONE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_EAU_CLAIRE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_INTRA_EAU_CLAIRE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_MOUNT_SIMON_SANDSTONE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_MIDDLE_MOUNT_SIMON_SANDSTONE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_LOWER_MOUNT_SIMON_SANDSTONE,
    MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BASAL_MOUNT_SIMON_SANDSTONE,
  ],
  [CARBON_LAYER_BASINS.APPALACHIA_STORAGE_CAPACITY]: [
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_BEREA_BRALLIER,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_OHIO_SHALE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_HURON_SHALE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_JAVA_OLENTANGY,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_RHINESTREET_SHALE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_MARCELLUS,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_ONONDAGA_ORISKANY,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_HELDERBERG_LIMESTONE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_SALINA_GROUP,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_SALINA_EVAPORITES,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_SALINA_C,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_LOCKPORT_DOLOMITE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_ROCHESTER_SHALE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_CLINTON_MEDINA,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_QUEENSTON_SHALE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_CINCINNATI_GROUP,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_UTICA_SHALE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_POINT_PLEASANT,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_TRENTON,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_BLACK_RIVER,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_WELLS_CREEK,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_KNOX,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_ROSE_RUN,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_COOPER_RIDGE_DOLOMITE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_LOWER_COOPER_RIDGE_DOLOMITE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_NULICHUCKY_SHALE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_MARRYVILLE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_MOUNT_SIMON_SANDSTONE,
    APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_BASEMENT,
  ],
};

export const DEFAULT_CARBON_STORAGE_BASINS_INFO = {
  [CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY]: {
    isSelected: false,
    formationName:
      CARBON_STORAGE_FORMATIONS_BY_BASINS[
        CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY
      ][0],
  },
  [CARBON_LAYER_BASINS.ILLINOIS_STORAGE_CAPACITY]: {
    isSelected: false,
    formationName:
      CARBON_STORAGE_FORMATIONS_BY_BASINS[
        CARBON_LAYER_BASINS.ILLINOIS_STORAGE_CAPACITY
      ][0],
  },
  [CARBON_LAYER_BASINS.GULF_COAST_STORAGE_CAPACITY]: {
    isSelected: false,
    formationName:
      CARBON_STORAGE_FORMATIONS_BY_BASINS[
        CARBON_LAYER_BASINS.GULF_COAST_STORAGE_CAPACITY
      ][0],
  },
  [CARBON_LAYER_BASINS.MICHIGAN_STORAGE_CAPACITY]: {
    isSelected: false,
    formationName:
      CARBON_STORAGE_FORMATIONS_BY_BASINS[
        CARBON_LAYER_BASINS.MICHIGAN_STORAGE_CAPACITY
      ][0],
  },
  [CARBON_LAYER_BASINS.APPALACHIA_STORAGE_CAPACITY]: {
    isSelected: false,
    formationName:
      CARBON_STORAGE_FORMATIONS_BY_BASINS[
        CARBON_LAYER_BASINS.APPALACHIA_STORAGE_CAPACITY
      ][0],
  },
};
